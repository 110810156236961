<template>
	<div class="bg-F7F7F7 paddt30">
		<div class="w1200 main bg-white">		
			<div class="relative app-bg">
				<img class="app-bg w100" src="@/assets/img/app_07.png" alt="" />
				<div class="absolute top0 left0 right0 bottom0 align-items-center flex-column z10">
					<div class="fz40  font-blod paddt110" style="opacity: 0;">建筑、工业设计学习APP</div>
					<div class="fz20  mart8"  style="opacity: 0;">专业资深设计师团队，一对一教学辅导</div>
					<div class="display-flex mart34">
						<!-- <div class="center ios-down boderra4 bg-white">
							<img class="app_01 display-block" src="@/assets/img/app_01.png" alt="" />
							<span class="co-000000 fz20 display-block marl6">IOS下载</span>
							
						</div> -->
						<div class="center android-down boderra4 bg-white flex-column ">
							<div class="center">
								<img class="app_01 display-block" src="@/assets/img/app_01.png" alt="" />
								<span class="co-000000 fz20 display-block marl6">IOS下载</span>
							</div>
							<div class="mart10">
								<el-image class="code display-block" :src="ios_package_qrcode" fit="fit" ></el-image>
							</div>
						</div>
						<div class="center android-down boderra4 bg-white flex-column marl20">
							<div class="center">
								<img class="app_01 display-block" src="@/assets/img/app_02.png" alt="" />
								<span class="co-000000 fz20 display-block marl6">Android下载</span>
							</div>
							<div class="mart10">
							
								<el-image class="code display-block" :src="android_package_qrcode" fit="fit" ></el-image>
							</div>
						</div>
					</div>
				</div>
				<img class="absolute app-03 z10" src="@/assets/img/app_03.png" alt="" />
			</div> 		
		 <div class="content relative">
				<!-- <div class="content-text op0">
					<div class="fz30 co-010085">App introduction</div>
					<div class="line bg-DCFF03 mart20"></div>
					<div class="fz24 co-333333 mart20">App介绍</div>
					<div class="fz16 co-333333 desc mart20">App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~App介绍~</div>
				</div> -->
				<div class="   ">
					<div class="content-text">
					<div v-html="pagedata" class="html-box ql-editor content-detail">
					</div>
					</div>
				</div>
			</div>
			
		</div>
		<div v-loading.fullscreen.lock="fullscreenLoading"></div>
	</div>
</template>

<script>
	export default {
		name: 'DownApp',
		data() {
			return {
				fullscreenLoading: true,
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
				pagedata:{},
				android_package_qrcode:'',
				ios_package_qrcode:''
			}
		},
		mounted() {
			this.$req({
				method: 'get',
				url: '/api/config',
				
				success: res => {
					console.log(res)
					this.pagedata =res.pc_app_introduction;
					
					this.android_package_qrcode =res.android_package_qrcode;
					this.ios_package_qrcode =res.ios_package_qrcode;
					this.fullscreenLoading =false;
				},
				fail: error => {}
			});
		},
		methods: {
			
		}
	}
</script>

<style lang="less" scope>
	
	.main {
		margin: 0 auto;
		
		.app-bg {
			height: 530px;
		}
		
		.ios-down {
			width: 170px;
			height: 50px;
			
			.app_01 {
				width: 30px;
				height: 30px;
			}
		}
		
		.android-down {
			padding-top: 10px;
			padding-left: 11px;
			padding-right: 11px;
			padding-bottom: 11px;
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
			
			.app_01 {
				width: 30px;
				height: 30px;
			}
			
			.code {
				width: 140px;
				height: 140px;
				margin: 0 auto;
			}
		}
		
		.app-03 {
			width: 178.2px;
			height: 358px;
			right: 211px;
			top: 331px;
		}
		
		.content {
			margin-top: -121px;
			
			.content-text {
				width: 864px;
				padding-left: 36px;
				padding-top: 30px;
				padding-bottom: 21px;
				background: #FAFAFA;
				margin: 0 auto;
				
				.line {
					width: 30px;
					height: 2px;
				}
				
				.desc {
					width: 559px;
					padding-bottom: 21px;
				}
			}
		}
		
		.img-area {
			width: 900px;
			margin: 0 auto;
			margin-top: 30px;
			padding-bottom: 30px;
			
			.img_02 {
				width: 440px;
				height: 270px;
			}
			
			.app_04 {
				width: 440px;
				height: 560px;
			}
		}
	}
</style>
